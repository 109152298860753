<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item >运营和营销</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/discount' }">优惠券</el-breadcrumb-item>
                <el-breadcrumb-item>新建优惠券</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" size="mini" >

                <el-form-item label="名称：" label-position="left" prop="name" >
                    <el-input style="width: 200px"  v-model="form.name"></el-input>
                </el-form-item>

                <el-form-item label="类型：" label-position="left" class="input" prop="type">
                    <el-select style="width: 200px" v-model="form.type" placeholder="请选择" @change="selectType">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="适用商品：" label-position="left" class="input" v-if="form.type === 2" prop="products">
                    <select-product @onSelect="onSelect"></select-product>
                </el-form-item>
                <el-form-item label="数量：" label-position="left" class="input" prop="count">
                    <el-input style="width: 200px"  v-model.number="form.count"></el-input>
                </el-form-item>
                <el-form-item label="面值：" label-position="left" class="input" prop="amount">
                    <el-input style="width: 200px"  v-model.number="form.amount"></el-input>
                </el-form-item>

                <el-form-item label="有效期：" label-position="left" class="input"  prop="startTime">
                    <el-date-picker
                            size="small"
                            v-model="value2"
                            type="datetimerange"
                            align="right"
                            unlink-panels
                            @change="changeDateTime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="状态：" label-position="left" class="input" >
                    <el-switch v-model="form.status" @change="setStatus">
                    </el-switch>
                    <span style="margin-left: 5px;font-size: 12px">{{statusTxt}}</span>
                </el-form-item>


                <el-form-item>
                    <el-button size="small"  type="primary" :loading="loading" @click="onSubmit('form')">保存</el-button>
                    <router-link to="/discount" class="router-link" ><el-button style="margin-left: 10px" size="small" >取消</el-button></router-link>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
    import {mapActions} from "vuex";
    import SelectProduct from "../../../components/selectProduct";
    export default {
        name: "template-add",
        components: {SelectProduct},
        data() {
            return {
                statusTxt:'上架',
                loading:false,
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() < (Date.now() - 3600 * 1000 * 24);
                    },
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                value2: '',
                form: {
                    name:'',
                    type: 2,
                    status:true,
                    amount:'',
                    count:'',
                    startTime:'',
                    endTime:'',
                    products:''
                },
                rules: {
                    name: [
                        { required: true, message: '请输入课程名称', trigger: 'blur' },
                    ],
                    type: [
                        { required: true, message: '请输入课程简介', trigger: 'blur' }
                    ],
                    count: [
                        { type: 'number', required: true, message: '请输入数量', trigger: 'blur' }
                    ],
                    products: [
                        { type:'array', required: true, message: '选择适用商品', trigger: 'blur' }
                    ],
                    amount: [
                        { type: 'number', required: true, message: '请输入面值', trigger: 'blur' }
                    ],
                    startTime: [
                        {  required: true,type: 'string', message: '请选择时间', trigger: 'change' },
                    ],
                },
                options: [
                    {
                        value: 1,
                        label: '全部商品通用'
                    },
                    {
                        value: 2,
                        label: '指定商品可用'
                    },
                ],

            }
        },
        methods: {
            ...mapActions('slideShow',['addSlideShow']),
            ...mapActions('marketing',['createDiscount']),
            async onSubmit(formName) {

                if(!this.validateForm(formName))
                {
                    this.$message.error('请填写必填选项！')
                    return false
                }

                let _this = this
                this.loading = true
                console.log(this.form)

               this.createDiscount(this.form).then(res => {
                   this.loading = false

                   if(res.ret == 0)
                   {
                       this.$message.success('新建优惠券成功！')
                       this.$router.push('/discount')
                   }
                })

            },
            setStatus(status){
                if(this.form.status){
                    this.statusTxt = '上架'
                }else{
                    this.statusTxt = '下架'
                }
                this.form.status = status
            },
            selectType(val){
                this.form.type = val
                if(this.form.type === 1){
                    this.form.products = ''
                }
            },
            onSelect(data){
                this.form.products = data
            },
            changeDateTime(value){
                this.form.startTime = value[0]
                this.form.endTime = value[1]
            },
            validateForm(formName){
                let validate = false
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        validate = true
                        return true
                    } else {
                        validate = false
                        return false;
                    }
                });
                return validate
            },
        }
    }
</script>

<style scoped>

</style>
